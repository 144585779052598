import React from "react";
import Desktop from "../../Components/Desktop";
const HomePage = () => {
  return (
    <div>
      <Desktop />
    </div>
  );
};

export default HomePage;
