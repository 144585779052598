import React, { useState, useEffect } from "react";
import "../Components/Desktop.css";
import temp from "../Images/post_og.png";
const Desktop = () => {
  const [showAbcd, setShowAbcd] = useState(false);
  const [showHumberg, setShowhamburg] = useState(false);
  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      const screenHeight = window.innerHeight;
      const scrollThreshold = screenHeight / 2; // Show Abcd div when scrolled half screen height

      if (scrollPosition > scrollThreshold) {
        setShowAbcd(true);
      } else {
        setShowAbcd(false);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);
  const handleHamburg = () => {
    setShowhamburg(!showHumberg);
  };
  console.log("nbvbn", showHumberg);
  return (
    <>
      <div className="ch-scroll-buffer" id="scroll-buffer"></div>
      <div>
        <div className="ch-landing">
          <header>
            <div
              className="ch-landing-img-container ch-avatar-group-block"
              id="ch-hand-logo-container"
            >
              <div className="ch-hand-wave-img-container">
                <img
                  className="ch-wave-img"
                  alt="clubhouse wave"
                  src="https://static-assets.clubhouseapi.com/static/img/img_hand_wave.3454a59f2b06.svg"
                  height="64"
                />
                <img
                  className="ch-wave-img"
                  alt="clubhouse wave"
                  src="https://static-assets.clubhouseapi.com/static/img/img_hand_wave.3454a59f2b06.svg"
                  style={{ clipPath: "inset(100% 0px 0px)" }}
                  id="ch-hand-logo-dark-theme"
                />
              </div>
            </div>
            <div
              className="ch-device-restricted--mobile ch-mobile-menu-container ch-theme ch-avatar-group-block"
              data-theme="light"
            >
              <a
                className="ch-mobile-get-app-link"
                // href="https://clubhouse.com/app"
              >
                get the app
              </a>
              <div
                className="ch-landing-img-container ch-device-restricted--mobile"
                id="ch-hamburger-menu-container"
                onClick={handleHamburg}
              >
                <img
                  className="ch-hamburger-menu-img"
                  id="ch-hamburger-menu-light-theme"
                  alt="menu"
                  src="https://static-assets.clubhouseapi.com/static/img/icon_hamburger_menu_dark.e3b1cf329cb8.svg"
                />
                <img
                  className="ch-hamburger-menu-img"
                  alt="menu"
                  src="https://static-assets.clubhouseapi.com/static/img/icon_hamburger_menu_dark.e3b1cf329cb8.svg"
                  style={{ clipPath: "inset(100% 0px 0px)" }}
                  id="ch-hamburger-menu-dark-theme"
                />
              </div>
            </div>
            <ul
              className="ch-nav-links ch-theme ch-avatar-group-block"
              data-theme="light"
            >
              <a
              // href="https://blog.clubhouse.com/"
              >
                <li>Blog</li>
              </a>
              <a
              // href="https://clubhouse.com/jobs"
              >
                <li>Career</li>
              </a>
              <li>
                <button
                  className="ch-btn--transparent ch-header-btn"
                  id="ch-header-cta-btn"
                >
                  Get Heardo
                </button>
              </li>
            </ul>
          </header>
          <main>
            <div className="ch-main-content-container">
              <div
                id="ch-logo-arrow-container"
                style={{ display: showAbcd ? "none" : "" }}
              >
                <div
                  className="ch-landing-img-container ch-avatar-group-block ch-main-content-slide-out"
                  id="ch-logo-container"
                >
                  <img
                    className="ch-landing-img ch-mount-animation--slide-bottom"
                    src="https://static-assets.clubhouseapi.com/static/img/clubhouse_logo_dark.2677abb66623.svg"
                    alt="Heardo logo"
                    id="ch-image-light"
                  />
                  {/* <img
                    className="ch-landing-img"
                    src="https://static-assets.clubhouseapi.com/static/img/clubhouse_logo_dark.2677abb66623.svg"
                    alt="Heardo logo"
                    id="ch-image-dark-theme"
                    style={{ clipPath: "inset(100% 0px 0px)" }}
                  /> */}
                </div>

                <div
                  className="ch-floating-arrow"
                  id="floating-arrow"
                  style={{ opacity: "1" }}
                >
                  <img
                    src="https://static-assets.clubhouseapi.com/static/img/icon_arrow_down.30d965af62c2.svg"
                    alt="scroll for more"
                    height="36"
                  />
                </div>
              </div>
              <div
                className="ch-action-text-container ch-main-content-slide-in"
                style={{ display: showAbcd ? "block" : "none" }}
              >
                <div
                  className="ch-theme ch-avatar-group-block ch-cta-text"
                  data-theme="light"
                >
                  <span className="ch-device-restricted--desktop">
                    friends over followers
                  </span>
                  <span className="ch-device-restricted--mobile">
                    friends over followers
                  </span>
                </div>
                <div className="ch-btn-container ch-theme" data-theme="light">
                  <div className="ch-device-restricted--mobile">
                    <a
                    // href="https://clubhouse.com/app"
                    >
                      <button
                        className="ch-btn--yellow ch-avatar-group-block"
                        id="ch-get-clubhouse-body-btn--mobile"
                      >
                        Get Heardo
                      </button>
                    </a>
                  </div>
                  <div className="ch-device-restricted--desktop ch-desktop-action-btn-container">
                    <button
                      className="ch-btn--yellow ch-avatar-group-block"
                      id="ch-get-clubhouse-body-btn"
                    >
                      Get Heardo
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </main>
        </div>
      </div>
      <div className="ch-scroll-page"></div>
      <div className="ch-overlay ch-hidden" id="ch-modal-overlay">
        <div className="ch-overlay-content">
          <div className="ch-modal">
            <div className="ch-modal-close-btn-container">
              <button
                className="ch-btn--transparent ch-btn--compact ch-btn--borderless"
                id="ch-modal-close-btn"
              >
                <img
                  alt="close modal"
                  src="https://static-assets.clubhouseapi.com/static/img/icon_close.0c3f7f7dc68d.svg"
                  height="24"
                />
              </button>
            </div>
            <div className="ch-modal-content">
              <img
                alt="scan qr code to download Heardo"
                src="https://static-assets.clubhouseapi.com/static/img/homepage/qr_code_dark.abd8babca33e.png"
                height="234"
              />
            </div>
            <div className="ch-modal-subtext-container">
              <div>
                <div className="ch-modal-download-arrow-container">
                  <img
                    className="ch-modal-download-arrow"
                    src="https://static-assets.clubhouseapi.com/static/img/icon_arrow_down.30d965af62c2.svg"
                    height="36"
                  />
                </div>
                <div className="ch-modal-subtext">Scan to download</div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`ch-hamburger-menu-content ${
          showHumberg === false ? "ch-hidden" : ""
        }
      ch-mount-animation--fly-in-right`}
      >
        <div className="ch-hamburger-menu-close-btn-container">
          <img
            alt="close menu"
            src="https://static-assets.clubhouseapi.com/static/img/icon_close.0c3f7f7dc68d.svg"
            height="28"
            id="ch-close-menu"
            onClick={handleHamburg}
          />
        </div>
        <ul
          className="ch-nav-links ch-avatar-group-block ch-mobile-nav-links"
          data-theme="dark"
        >
          <a
          // href="https://blog.clubhouse.com/"
          >
            <li>Blog</li>
          </a>
          <a
          // href="https://clubhouse.com/jobs"
          >
            <li>Jobs</li>
          </a>
          <a
          // href="https://clubhouse.com/app"
          >
            <li>
              <button className="ch-btn--transparent">Get Heardo</button>
            </li>
          </a>
        </ul>
        <footer className="ch-hamburger-footer">
          <ul className="ch-footer-links">
            <li>
              <a
              // href="https://privacy.clubhouse.com/"
              >
                privacy
              </a>
            </li>
            <li>
              <a
              // href="https://tos.clubhouse.com/"
              >
                terms
              </a>
            </li>
            <li>
              <a
              // href="https://support.clubhouse.com/"
              >
                FAQs
              </a>
            </li>
          </ul>
          <ul className="ch-footer-links ch-hamburger-social-links">
            <li>
              <a
              // href="https://www.twitter.com/clubhouse"
              >
                <img
                  src="https://static-assets.clubhouseapi.com/static/img/icon_twitter_web_dark.9a4902b61f14.svg"
                  alt="Heardo twitter"
                  className="ch-social-icon"
                  height="18"
                />
              </a>
            </li>
            <li>
              <a
              // href="https://www.instagram.com/clubhouse"
              >
                <img
                  src="https://static-assets.clubhouseapi.com/static/img/icon_instagram_web_dark.adaf800aa724.svg"
                  alt="Heardo instagram"
                  className="ch-social-icon"
                  height="18"
                />
              </a>
            </li>
          </ul>
        </footer>
        <div className="ch-copyright ch-hamburger-copyright">
          © <span className="ch-copyright-year">2023</span> Alpha Exploration
          Co.
        </div>
      </div>
      <footer className="ch-device-restricted--desktop">
        <ul className="ch-footer-links ch-avatar-group-block">
          <li>
            <a
            // href="https://privacy.clubhouse.com/"
            >
              privacy
            </a>
          </li>
          <li>
            <a
            // href="https://tos.clubhouse.com/"
            >
              terms
            </a>
          </li>
          <li>
            <a
            // href="https://support.clubhouse.com/"
            >
              FAQs
            </a>
          </li>
          <li>
            <a
            // href="https://www.twitter.com/clubhouse"
            >
              <img
                src="https://static-assets.clubhouseapi.com/static/img/icon_twitter_web_ch2.c75ce32d753d.svg"
                alt="Heardo twitter"
                className="ch-social-icon"
                height="16"
              />
            </a>
          </li>
          <li>
            <a
            // href="https://www.instagram.com/clubhouse"
            >
              <img
                src="https://static-assets.clubhouseapi.com/static/img/icon_instagram_web_ch2.268a91db2063.svg"
                alt="Heardo instagram"
                className="ch-social-icon"
                height="16"
              />
            </a>
          </li>
        </ul>
        <div className="ch-copyright ch-avatar-group-block">
          © <span className="ch-copyright-year">2023</span> Alpha Exploration
          Co.
        </div>
      </footer>
      <div id="avatar-groups">
        <div className="ch-avatar-group-block"></div>
        <div
          className="ch-avatar-group ch-avatar-group--3 ch-avatar-group-block"
          style={{ transform: "translate(739.757px, 991.468px)" }}
        >
          <div
            className="ch-avatar-container ch-avatar-container--pulse "
            style={{
              transform: "rotate(0deg)",
              opacity: "1",
              transition:
                "all 2.35237s cubic-bezier(0.285859, 0.701174, 0.520683, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
                width: "100%",
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container"
            style={{
              transform: "rotate(0deg)",
              opacity: "1",
              transition:
                "all 6.09407s cubic-bezier(0.215308, 0.648812, 0.516638, 1) 0s;",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container"
            style={{
              transform: "rotate(0deg)",
              opacity: "1",
              transition:
                "all 6.15555s cubic-bezier(0.27593, 0.679141, 0.511667, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
        </div>
        <div
          className="ch-avatar-group ch-avatar-group--4 ch-avatar-group-block"
          style={{ transform: "translate(366.769px, 319.139px)" }}
        >
          <div
            className="ch-avatar-container"
            style={{
              left: "939.231px",
              top: "-607.693px",
              transform: "rotate(17.3643deg)",
              opacity: "1",
              transition: "all 2.85327s linear 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container"
            style={{
              left: "-366.769px",
              top: "-565.035px",
              transform: "rotate(-6.19896deg)",
              opacity: "1",
              transition: "all 2.99296s linear 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container"
            style={{
              left: "939.231px",
              top: "-519.255px",
              transform: "rotate(-68.2104deg)",
              opacity: "1",
              transition: "all 3.64921s linear 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container"
            style={{
              left: "939.231px",
              top: "-508.538px",
              transform: "rotate(26.7434deg)",
              opacity: "1",
              transition: "all 4.00734s linear 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
        </div>

        <div
          className="ch-avatar-group ch-avatar-group--5 ch-avatar-group-block"
          style={{
            transform: "translate(1035.16px, 724.743px)",
            animation: "5.87919s ease-in-out 0s 1 normal none running fade-out",
          }}
        >
          <div
            className="ch-avatar-container ch-avatar-container--pulse"
            // style={{
            //   transform: "rotate(0deg)",
            //   opacity: "1",
            //   transition:
            //     "all 2.46843s cubic-bezier(0.200066, 0.70689, 0.596693, 1) 0s",
            // }}
            style={{
              right: "939.231px",
              top: "-607.693px",
              transform: "rotate(-17.3643deg)",
              opacity: "1",
              transition:
                "all 2.46843s cubic-bezier(0.200066, 0.70689, 0.596693, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container ch-avatar-container--pulse"
            // style={{
            //   transform: "rotate(0deg)",
            //   opacity: "1",
            //   transition:
            //     "all 3.81433s cubic-bezier(0.259715, 0.621252, 0.551524, 1) 0s",
            // }}
            style={{
              right: "-366.769px",
              top: "-565.035px",
              transform: "rotate(6.19896deg)",
              opacity: "1",
              transition:
                "all 3.81433s cubic-bezier(0.259715, 0.621252, 0.551524, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container ch-avatar-container--pulse"
            // style={{
            //   transform: "rotate(0deg)",
            //   opacity: "1",
            //   transition:
            //     "all 3.83311s cubic-bezier(0.242979, 0.66433, 0.586234, 1) 0s",
            // }}
            style={{
              right: "939.231px",
              top: "-519.255px",
              transform: "rotate(68.2104deg)",
              opacity: "1",
              transition:
                "all 3.83311s cubic-bezier(0.242979, 0.66433, 0.586234, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container ch-avatar-container--pulse"
            // style={{
            //   transform: "rotate(0deg)",
            //   opacity: "1",
            //   transition:
            //     "all 5.18239s cubic-bezier(0.213814, 0.671066, 0.564567, 1) 0s",
            // }}
            style={{
              right: "939.231px",
              top: "-508.538px",
              transform: "rotate(-26.7434deg)",
              opacity: "1",
              transition:
                "all 5.18239s cubic-bezier(0.213814, 0.671066, 0.564567, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container"
            // style={{
            //   transform: "rotate(0deg)",
            //   opacity: "1",
            //   transition:
            //     "all 6.35749s cubic-bezier(0.221158, 0.644564, 0.53625, 1) 0s",
            // }}
            style={{
              right: "939.231px",
              top: "-508.538px",
              transform: "rotate(-56.7434deg)",
              opacity: "1",
              transition:
                "all 6.35749s cubic-bezier(0.221158, 0.644564, 0.53625, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
        </div>
        <div
          className="ch-avatar-group ch-avatar-group--6 ch-avatar-group-block"
          style={{
            transform: "translate(749.997px, 158.561px)",
            animation: "5.17806s ease-in-out 0s 1 normal none running fade-out",
          }}
        >
          <div
            className="ch-avatar-container ch-avatar-container--pulse"
            style={{
              transform: "rotate(0deg)",
              opacity: "1",
              transition:
                "all 2.29017s cubic-bezier(0.202183, 0.706619, 0.56059, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container ch-avatar-container--pulse"
            style={{
              transform: "rotate(0deg)",
              opacity: "1",
              transition:
                "all 2.45211s cubic-bezier(0.236229, 0.649504, 0.552064, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container ch-avatar-container--pulse"
            style={{
              transform: "rotate(0deg)",
              opacity: "1",
              transition:
                "all 4.74645s cubic-bezier(0.21687, 0.7076, 0.588765, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container ch-avatar-container--pulse"
            style={{
              transform: "rotate(0deg)",
              opacity: "1",
              transition:
                "all 5.50634s cubic-bezier(0.256972, 0.658324, 0.568859, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
        </div>
        <div className="ch-avatar-group ch-avatar-group--5"></div>
        <div
          className="ch-avatar-group ch-avatar-group--7 ch-avatar-group-block"
          style={{ transform: "translate(101.325px, 914.854px)" }}
        >
          <div
            className="ch-avatar-container"
            style={{
              transform: "rotate(0deg)",
              opacity: "1",
              transition:
                "all 2.53179s cubic-bezier(0.255236, 0.70415, 0.555448, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container"
            style={{
              transform: "rotate(0deg)",
              opacity: "1",
              transition:
                "all 3.99094s cubic-bezier(0.275104, 0.70863, 0.544689, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
          <div
            className="ch-avatar-container"
            style={{
              transform: "rotate(0deg)",
              opacity: "1",
              transition:
                "all 5.67103s cubic-bezier(0.219103, 0.693702, 0.596497, 1) 0s",
            }}
          >
            <div
              className="ch-avatar rectangle"
              style={{
                backgroundImage: `url(${temp})`,
              }}
            ></div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Desktop;
